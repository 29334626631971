import React, {useCallback, useEffect, useState} from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    FormFeedback
} from 'reactstrap';
import ReactLadda from 'react-ladda';
import {
    withGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import {getLinkObj, getText} from "../utils/ContentHelpers";
import DatePicker from "react-datepicker";
import HelpModalWrapper from "./HelpModalWrapper";
import HelpModalBody from "../workflows/AuthPage/components/HelpModalBody";
import InputInstructions from "../workflows/AuthPage/components/InputInstructions";
import {getAuthHelpContent} from "../requestActions/contentActions";
import {AuthFromValues, AuthHelpBagContentItem, AuthHelpResponse} from "../types";
import ErrorAlert from "./ErrorAlert";

interface ReAuthenticationModalProps {
    authConfirm: (
        saveAuthResults: ({authLoading, authErrors, displayAuthErrors}: {authLoading: boolean, displayAuthErrors: boolean, authErrors: any[]}) => void
    ) => (authFormValues: AuthFromValues, reCaptchaToken: string) => Promise<boolean>
    authText: any
    isOpen: boolean
    toggleModal: () => void
    googleReCaptchaProps?: any
    language: string
}

const ReAuthenticationModal: React.FC<ReAuthenticationModalProps> = ({
    authConfirm,
    authText,
    isOpen,
    toggleModal,
    googleReCaptchaProps,
    language
}) => {
    const [state, setState] = useState<{
        authLoading: boolean,
        authErrors: any[],
        displayAuthErrors: boolean,
        lastName: string,
        dob: Date | null,
        idNum: string,
        ssn: string,
        pin: string,
        ddn: string,
        ssnError: string,
        lastNameError: string,
        dobError: string,
        idNumError: string,
        lastNameInvalid: boolean,
        dobInvalid: boolean,
        idNumInvalid: boolean,
        ssnIsInvalid: boolean,
        requireOne: boolean,
        requireOneError: string,
        displayPIDHelp: boolean,
        displayPINHelp: boolean,
        displayDDNHelp: boolean,
        PIDHelpContent: any,
        PINHelpContent: any,
        DDNHelpContent: any
    }>({
        authLoading: false,
        authErrors: [],
        displayAuthErrors: false,
        lastName: '',
        dob: null,
        idNum: '',
        ssn: '',
        pin: '',
        ddn: '',
        ssnError: '',
        lastNameError: '',
        dobError: '',
        idNumError: '',
        lastNameInvalid: false,
        dobInvalid: false,
        idNumInvalid: false,
        ssnIsInvalid: false,
        requireOne: false,
        requireOneError: '',
        displayPIDHelp: false,
        displayPINHelp: false,
        displayDDNHelp: false,
        PIDHelpContent: null,
        PINHelpContent: null,
        DDNHelpContent: null
    });
    const getHelpModalContent = useCallback(() => {
        getAuthHelpContent(language)
            .then((res: AuthHelpResponse) => {
                if (res?.data?.BagPart?.ContentItems?.length) {
                    parseAuthHelpContent(res.data.BagPart.ContentItems);
                }
            })
            .catch((error: any) => {
                console.error(error);
            });
    }, []);

    const parseAuthHelpContent = (contentArray: Array<AuthHelpBagContentItem>) => {
        type helpAliases = 'auth-content-help-pid'|'auth-content-help-pin'|'auth-content-help-ddn';
        type helpStateNames = 'PIDHelpContent'|'PINHelpContent'|'DDNHelpContent';

        const helpAliasToStateMap: Record<helpAliases, helpStateNames> = {
            'auth-content-help-pid': 'PIDHelpContent',
            'auth-content-help-pin': 'PINHelpContent',
            'auth-content-help-ddn': 'DDNHelpContent'
        } as const;
        const helpContent: Record<helpStateNames, any> = {
            PIDHelpContent: null,
            PINHelpContent: null,
            DDNHelpContent: null
        }
        contentArray.forEach((contentItem) => {
            const contentItemAlias = contentItem?.AliasPart?.Alias as helpAliases;
            if (helpAliasToStateMap[contentItemAlias] && contentItem.HelpPopupModal) {
                helpContent[helpAliasToStateMap[contentItemAlias]] = contentItem.HelpPopupModal;
            }
        });
        setState(prevState => ({ ...prevState, ...helpContent}));
    };

    useEffect(() => {
        getHelpModalContent();
    }, [getHelpModalContent]);

    const verifySSN = (ssn?: string) => {
        // user hasn't entered SSN
        if (!ssn?.length) {
            setState(prevState => ({
                ...prevState,
                ssnError: '',
                ssnIsInvalid: false
            }));
            return true;
        }

        if (ssn.length < 9 || ssn.length > 9) {
            setState(prevState => ({
                ...prevState,
                ssnError: getText(authText, 'InvalidSSNLength'),
                ssnIsInvalid: true
            }));
            return false;
        }

        const ssnTest = RegExp(/^(\d{9})$/, 'g');
        if (!ssnTest.test(ssn)) {
            setState(prevState => ({
                ...prevState,
                ssnError: getText(authText, 'InvalidSSNFormat'),
                ssnIsInvalid: true
            }));
            return false;
        }

        // No errors found
        setState(prevState => ({
            ...prevState,
            ssnError: '',
            ssnIsInvalid: false
        }));
        return true;
    };

    const verifyLastName = () => {
        if (!state.lastName) {
            setState(prevState => ({
                ...prevState,
                lastNameError: getText(authText, 'LastNameRequired'),
                lastNameInvalid: true
            }));
            return false;
        }

        setState(prevState => ({
            ...prevState,
            lastNameError: '',
            lastNameInvalid: false
        }));
        return true;
    };

    const dobIsInPast = (dob: Date | null) => {
        const dobTest = dob || new Date();
        const now = new Date();
        return dobTest.getTime() < now.getTime();
    };

    const verifyDOB = () => {
        if (!state.dob) {
            setState(prevState => ({
                ...prevState,
                dobError: getText(authText, 'DOBRequired'),
                dobInvalid: true
            }));
            return false;
        }
        if (!dobIsInPast(state.dob)) {
            setState(prevState => ({
                ...prevState,
                dobError: getText(authText, 'DOBNotInFuture'),
                dobInvalid: true
            }));
            return false;
        }

        setState(prevState => ({
            ...prevState,
            dobError: '',
            dobInvalid: false
        }));
        return true;
    };

    const verifyIdNum = () => {
        if (!state.idNum) {
            setState(prevState => ({
                ...prevState,
                idNumError: getText(authText, 'IDNumberRequired'),
                idNumInvalid: true
            }));
            return false;
        }
        setState(prevState => ({
            ...prevState,
            idNumError: '',
            idNumInvalid: false
        }));
        return true;
    };

    const ssnPinOrDDNHasValue = () => {
        const { ssn, pin, ddn } = state;

        if (ssn || pin || ddn) {
            setState(prevState => ({
                ...prevState,
                requireOne: false,
                requireOneError: ''
            }));
            return true;
        }

        setState(prevState => ({
            ...prevState,
            requireOne: true,
            requireOneError: getText(authText, 'OneIsRequired')
        }));
        return false;
    };

    const onDOBChange = (date: Date) => {
        setState(prevState => ({ ...prevState, dob: date}));
    };

    const onInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const onSSNChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        const ssn = value.replace(/[^\d]/g, '');
        verifySSN(ssn);
        setState(prevState => ({...prevState, ssn: ssn}));
    };

    const toggleAuthErrorDisplay = () => setState(prevState => ({ ...prevState, displayAuthErrors: !prevState.displayAuthErrors }))
    const saveAuthResults = ({authLoading, authErrors, displayAuthErrors}: {authLoading: boolean, displayAuthErrors: boolean, authErrors: any[]}) => {
        setState(prevState => ({ ...prevState, authLoading, authErrors, displayAuthErrors }))
    }
    const onClickConfirm = (event: any) => {
        event.preventDefault();
        if (
            verifyLastName()
            && verifyDOB()
            && verifyIdNum()
            && ssnPinOrDDNHasValue()
            && verifySSN(state.ssn)
        ) {
            setState(prevState => ({ ...prevState, authLoading: true }));
            googleReCaptchaProps.executeRecaptcha('authenticateUser')
                .then((token: string) => {
                    const {lastName, dob, idNum, ssn, pin, ddn} = state;
                    return authConfirm(saveAuthResults)({
                        lastName,
                        dob,
                        idNum,
                        ssn,
                        pin,
                        ddn
                    }, token)
                })
                .then((authSuccessful: boolean) => authSuccessful && toggleModal());
        }
    };

    const onClickCancel = (event: any) => {
        toggleModal();
    };

    const ssnInvalidClass = state.ssnIsInvalid || state.requireOne ? 'is-invalid' : '';
    const dobInvalidClass = state.dobInvalid ? 'is-invalid' : '';
    return (
        <Modal isOpen={isOpen} toggle={toggleModal} size="xl">
            <ModalBody>
                <Form onSubmit={onClickConfirm}>
                    <Row >
                        <Col>
                            <Row>
                                <Col>
                                    <div className="auth-column-head">
                                        {getText(authText, 'LeftColHeading')}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="auth-text-greyed">
                                        {getText(authText, 'LeftColSubHeading')}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8}>
                                    {/* Trying to prevent the column from getting to small */}
                                    <div className="auth-column-width"></div>
                                    <Row className="mt-4">
                                        <Col>
                                            <FormGroup>
                                                <Label for="lastName">
                                                    {getText(authText, 'LastNameLabel')}*
                                                </Label>
                                                <Input
                                                    id="lastNameInput"
                                                    name="lastName"
                                                    onChange={onInputChange}
                                                    required={true}
                                                    value={state.lastName}
                                                    invalid={state.lastNameInvalid}
                                                    onBlur={verifyLastName}
                                                />
                                                <FormFeedback
                                                    invalid={state.lastNameInvalid}
                                                >
                                                    {state.lastNameError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <FormGroup>
                                                <Label for="dob">{getText(authText, 'DOBLabel')}*</Label>
                                                <br />
                                                <DatePicker
                                                    id="dobInput"
                                                    name="dob"
                                                    className={`form-control ${dobInvalidClass}`}
                                                    selected={state.dob}
                                                    required={true}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    placeholderText="MM/DD/YYYY"
                                                    maxDate={new Date()}
                                                    timeFormat="MM/DD/YYYY"
                                                    onChange={onDOBChange}
                                                    onBlur={verifyDOB}
                                                />
                                                <FormFeedback
                                                    style={{display: 'block', margin: 0}}
                                                    invalid={state.dobInvalid}
                                                >
                                                    {state.dobError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <FormGroup>
                                                <Label for="idNum">
                                                    {getText(authText, 'IDNumLabel')}*&nbsp;
                                                    <HelpModalWrapper
                                                        headerTitle={state.PIDHelpContent}
                                                    >
                                                        <HelpModalBody content={state.PIDHelpContent} />
                                                    </HelpModalWrapper>
                                                </Label>
                                                <Input
                                                    id="idNumInput"
                                                    name="idNum"
                                                    onChange={onInputChange}
                                                    required={true}
                                                    invalid={state.idNumInvalid}
                                                    onBlur={verifyIdNum}
                                                    value={state.idNum}
                                                />
                                                <FormFeedback
                                                    invalid={state.idNumInvalid}
                                                >
                                                    {state.idNumError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="auth-column-head">
                                        {getText(authText, 'RightColHeading')}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="auth-text-greyed">
                                        {getText(authText, 'RightColSubHeading')}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8}>
                                    <div className="auth-column-width"></div>
                                    <Row className="mt-4">
                                        <Col>
                                            <FormGroup>
                                                <Label for="ssn">{getText(authText, 'SSNLabel')}</Label>
                                                <input
                                                    name="ssn"
                                                    type="text"
                                                    inputMode="numeric"
                                                    className={`form-control valid-password-field ${ssnInvalidClass}`}
                                                    autoComplete="off"
                                                    onChange={onSSNChange}
                                                    onBlur={ssnPinOrDDNHasValue}
                                                    value={state.ssn}
                                                    maxLength={9}
                                                    placeholder=""

                                                />
                                                <FormFeedback
                                                    invalid={state.ssnIsInvalid || state.requireOne}
                                                >
                                                    {state.ssnError || state.requireOneError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <FormGroup>
                                                <Label for="pin">
                                                    {getText(authText, 'PINLabel')}&nbsp;
                                                    <HelpModalWrapper
                                                        headerTitle={state.PINHelpContent}
                                                    >
                                                        <HelpModalBody
                                                            content={state.PINHelpContent}
                                                        />
                                                    </HelpModalWrapper>
                                                </Label>
                                                <Input
                                                    name="pin"
                                                    onChange={onInputChange}
                                                    invalid={state.requireOne}
                                                    value={state.pin}
                                                    onBlur={ssnPinOrDDNHasValue}
                                                />
                                                <FormFeedback
                                                    invalid={state.requireOne}
                                                >
                                                    {state.requireOneError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <FormGroup>
                                                <Label for="ddn">
                                                    {getText(authText, 'DDNLabel')}&nbsp;
                                                    <HelpModalWrapper
                                                        headerTitle={state.PINHelpContent}
                                                    >
                                                        <HelpModalBody
                                                            content={state.DDNHelpContent}
                                                        />
                                                    </HelpModalWrapper>
                                                </Label>
                                                <Input
                                                    name="ddn"
                                                    onChange={onInputChange}
                                                    invalid={state.requireOne}
                                                    value={state.ddn}
                                                    onBlur={ssnPinOrDDNHasValue}
                                                />
                                                <FormFeedback
                                                    invalid={state.requireOne}
                                                >
                                                    {state.requireOneError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <ErrorAlert
                                errors={state.authErrors}
                                displayErrors={state.displayAuthErrors}
                                toggleErrorDisplay={toggleAuthErrorDisplay}
                            />
                        </Col>
                    </Row>
                    <InputInstructions
                        content={authText}
                        ssn={state.ssn}
                        pin={state.pin}
                        ddn={state.ddn}
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <ReactLadda
                    className="cancel-btn"
                    loading={state.authLoading}
                    onClick={onClickCancel}
                >
                    {getLinkObj(authText, 'CancelBtn').Text || ''}
                </ReactLadda>
                <ReactLadda
                    className="general-btn"
                    loading={state.authLoading}
                    onClick={onClickConfirm}
                >
                    {getText(authText, 'ConfirmButtonText')}
                </ReactLadda>
            </ModalFooter>
        </Modal>
    );
};

export default withGoogleReCaptcha(ReAuthenticationModal)
