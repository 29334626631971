import React from 'react';
import {Button, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {getText} from '../utils/ContentHelpers';
import AddressManagement from '../workflows/EditInformation/AddressManagement';
import {
    formatHeightFeet,
    formatHeightInches,
    formatWeight,
    isEnhancedLogin,
    parseCurrency
} from '../utils/UtilityFunctions';
import {Redirect} from 'react-router-dom';
import {getCredEditContent} from '../requestActions/contentActions';
import LoadingIcon from '../components/LoadingIcon';
import Confirmation from './Confirmation';
import ErrorAlert from '../components/ErrorAlert';
import {getFieldOptionsAction} from '../requestActions/configActions';
import {Addresses, AuthFromValues} from "../types";

export interface Props {
    authConfirm: (
        saveAuthResults: ({authLoading, authErrors, displayAuthErrors}: {authLoading: boolean, displayAuthErrors: boolean, authErrors: any[]}) => void
    ) => (authFormValues: AuthFromValues, reCaptchaToken: string) => Promise<boolean>
    authText: any
    jwt: string
    location: any
    language: string
}

class EditInformation extends React.Component <Props> {
    AddressCompRef: any = null;
    state = {
        selectedCredential: {} as any,
        replacementReason: undefined,
        newAddresses: [] as Addresses,
        heightFeet: '',
        heightFeetInvalid: undefined,
        heightFeetError: undefined,
        heightInches: '',
        heightInchesInvalid: undefined,
        heightInchesError: undefined,
        weight: undefined,
        weightInvalid: undefined,
        weightError: undefined,
        eyeColor: undefined,
        gender: undefined,
        organDonor: undefined,
        currentEmail: '',
        email: '',
        emailInvalid: undefined,
        emailError: undefined,
        emailConfirmation: '',
        emailConfirmationInvalid: undefined,
        emailConfirmationError: undefined,
        receiveEmail: false,
        currentPhone: '',
        phone: '',
        phoneInvalid: undefined,
        phoneError: undefined,
        phoneConfirmation: '',
        phoneConfirmationInvalid: undefined,
        phoneConfirmationError: undefined,
        receiveSMS: false,
        medicalDisclosure: undefined,
        cdlSelfCertificationType: undefined,
        citizen: undefined,
        legalPresence: undefined,
        resident: undefined,
        declineVoter: false,
        price: undefined,
        newServiceType: undefined,
        renderConfirm: false,
        loading: true,
        content: null,
        eyeColorList: {} as any,
        genderList: {} as any,
        replacementReasonList: {} as any,
        addressContent: {} as any,
        selectedPriceObj: {} as any,
        validationErrorsObj: {} as any,
        spMailingHelp: {} as any,
        displayErrorAlert: false,
        alertErrors: [] as Array<string>,
        fieldOptions: {},
        isForeignAddress: false,
        isAPOAddress: false,
        keepSchoolBusEndorsement: undefined,
        takenSchoolBusClinic: undefined,
        keepHazardousMaterialsEndorsement: undefined,
        submittedFingerprints: undefined
    }

    isForeignAddressState = (value: boolean) => {
        let modifyAddressClass = this.state.newAddresses.map((a) => {
            if (a.addressType === 3){
                a.addressClass = value ? 3 : 0
            }
            return a;
        });
        this.setState({
            isForeignAddress: value,
            newAddresses: modifyAddressClass
        });
    }

    isAPOAddressState = (value: boolean) => {
        this.setState({
            isAPOAddress: value
        });
    }

    setNewAddressState = (addresses: any) => {
        this.setState({
            newAddresses: addresses
        });
    }

    componentDidMount() {
        this.getContent();
    }

    renewalIsRestricted = (credential: any) => {
        return credential.prices.find((x: any) => x.name === 'Restricted - 1 year') !== undefined;
    }

    setRecordData = () => {
        const record: any = {};
        if (this.props.location && this.props.location.state) {
            const credential = this.props.location.state.selectedCredential;
            if (credential && this.state.newAddresses !== credential.contextData.addresses) {
                record.newAddresses = credential.contextData.addresses
            }
            record.selectedCredential = credential;
            record.heightFeet = credential.contextData.heightfeet;
            record.heightInches = credential.contextData.heightinches;
            record.weight = credential.contextData.weight;
            record.eyeColor = credential.contextData.eyecolor;
            record.gender = credential.contextData.gender;
            record.organDonor = credential.contextData.organdonor;
            record.newServiceType = this.props.location.state.newServiceType;
            record.currentPhone = credential.contextData.telephone || '';
            record.currentEmail = credential.contextData.email || '';

            if (record.newServiceType === 'renew' && this.renewalIsRestricted(credential)) {
                const restrictedPrice = credential.prices.find((x: any) => x.name === 'Restricted - 1 year');
                record.selectedPriceObj = restrictedPrice;
                record.price = restrictedPrice.currency;
            }

        }
        return record;
    }

    getContent = () => {
        getCredEditContent(this.props.language)
            .then((res: any) => {
                const newState = this.setRecordData();
                newState.content = res.data.BagPart.ContentItems[0].EditsAndChanges
                newState.eyeColorList = res.data.BagPart.ContentItems[1].EyeColorList
                newState.replacementReasonList = res.data.BagPart.ContentItems[2].ReplacementReason
                newState.addressContent = res.data.BagPart.ContentItems[3].Address
                newState.validationErrorsObj = res.data.BagPart.ContentItems[4].EditAndChangesValidationErrors
                newState.spMailingHelp = res.data.BagPart.ContentItems[5].HelpPopupModal
                newState.genderList = res.data.BagPart.ContentItems[6].GenderList
                newState.loading = false;

                newState.eyeColorList = {
                    "": {
                        "Text": ""
                    },
                    ...newState.eyeColorList,
                }

                newState.genderList = {
                    "": {
                        "Text": ""
                    },
                    ...newState.genderList,
                }

                this.setState(newState);
            })
            .catch((error: any) => {
                console.error(error);
            });

        const apoCity = getFieldOptionsAction('apocity');
        const apoState = getFieldOptionsAction('apostate');
        const addrState = getFieldOptionsAction('addrstate');

        Promise.all([apoCity, apoState, addrState])
            .then((res: Array<any>) => {
                this.setState({
                    fieldOptions: {
                        apocity: res[0].data,
                        apostate: res[1].data,
                        addrstate: res[2].data
                    }
                })
            })
            .catch((error: any) => {
                console.error(error);
            });
    }

    onClickSubmit = (event: any) => {
        // there will be a 'fields' attr that will require the following: email, receiveEmail, phone, receiveSMS (per Andrew Helmkamp)
        event.preventDefault();
        const { selectedCredential } = this.props.location.state;

        let errors: Array<string> = [];
        // validate email
        errors = this.onSubmitErrors(errors, this.compareEmail, 'CompareEmailError');
        errors = this.onSubmitErrors(errors, this.verifyEmail, 'PhoneFormatError');

        // validate phone
        errors = this.onSubmitErrors(errors, this.comparePhone, 'ComparePhoneError');
        errors = this.onSubmitErrors(errors, this.phoneFormat, 'PhoneFormatError');

        // require duration for renew
        errors = this.onSubmitErrors(errors, this.requireDuration, 'DurationRequired');

        // require reason for replace
        errors = this.onSubmitErrors(errors, this.requireReason, 'ReplaceReasonRequired');

        // validate height inches
        errors = this.onSubmitErrors(errors, this.heightInchesFormat, 'HeightInchesRequired');

        // validate height feet
        errors = this.onSubmitErrors(errors, this.heightFeetFormat, 'HeightFeetRequired');

        // validate weight
        errors = this.onSubmitErrors(errors, this.weightFormat, 'WeightRequired');

        // require medical answer
        if (selectedCredential.contextData.displaymedicalquestion) {
            errors = this.onSubmitErrors(errors, this.medicalRequired, 'MedicalAnswerRequired');
        }

        // require organ donor answer
        errors = this.onSubmitErrors(errors, this.organDonorRequired, 'OrganDonorAnswerRequired');

        // require cdl self certification answer
        errors = this.onSubmitErrors(errors, this.cdlSelfCertificationRequired, 'CDLSelfCertificationAnswerRequired');

        // require legal presence answer
        errors = this.onSubmitErrors(errors, this.requireLegalPresence, 'LegalPresenceAnswerRequired');

        // require voter
        errors = this.onSubmitErrors(errors, this.requireVoterAnswers, 'VoterAnswerRequired');

        // require school bus clinic question if applicable
        errors = this.onSubmitErrors(errors, this.requireSchoolBusQuestion, 'SchoolBusAnswerRequired')

        // require hazardous materials question if applicable
        errors = this.onSubmitErrors(errors, this.requireHazardousMaterialsQuestion, 'HazardousMaterialQuestionRequired')

        // require
        errors = this.onSubmitErrors(errors, this.requireSchoolBusTestYesIfApplicable, 'SchoolBusTestRequired');

        //
        errors = this.onSubmitErrors(errors, this.requireFingerprintsYesIfApplicable, 'FingerprintsRequired');

        // verify address only in enhanced login
        if (isEnhancedLogin()) {
            errors = this.onSubmitErrors(errors, this.verifyAddresses, 'AddressValidationErrors');
        }

        if (
            errors
            && errors.length
        ) {
            this.setState({
                alertErrors: errors,
                displayErrorAlert: true
            });
        } else {
            this.setState({
                displayErrorAlert: false,
                alertErrors: [],
                renderConfirm: true
            });
        }
    }

    onSubmitErrors = (errors: Array<string>, testFunc: () => boolean, errorText: string): Array<string> => {
        // temporary workaround until CMS is fixed
        const CDLSelfCertificationAnswerRequired = 'Answer to CDL self certification is required';
        const LegalPresenceAnswerRequired = 'Answer to citizenship questions is required';
        const SchoolBusAnswerRequired = 'Answer to School Bus Clinic question required when keeping a School Bus endorsement';
        const HazardousMaterialQuestionRequired = 'Answer to Hazardous Materials question required when keeping a Hazardous Materials endorsement';
        const SchoolBusTestRequired = 'Your application cannot be processed online.  Please call 802-828-0598';
        const FingerprintsRequired = 'Your application cannot be processed online.  Please call 802-828-0598';

        if (!testFunc()) {
            if (errorText === 'CDLSelfCertificationAnswerRequired') {
                errors.push(CDLSelfCertificationAnswerRequired);
            }
            else if (errorText === 'LegalPresenceAnswerRequired') {
                errors.push(LegalPresenceAnswerRequired);
            }
            else if (errorText === 'SchoolBusAnswerRequired'){
                errors.push(SchoolBusAnswerRequired)
            }
            else if (errorText === 'HazardousMaterialQuestionRequired'){
                errors.push(HazardousMaterialQuestionRequired)
            }
            else if (errorText === 'SchoolBusTestRequired'){
                errors.push(SchoolBusTestRequired);
            }
            else if (errorText === 'FingerprintsRequired'){
                errors.push(FingerprintsRequired);
            }
            else {
                errors.push(getText(this.state.validationErrorsObj, errorText));
            }
        }
        return errors;
    }

    verifyAddresses = () => {
        const { newAddresses } = this.state;
        let passedValidation = true;
        if (newAddresses && newAddresses.length) {
            newAddresses.forEach((address: any, index) => {
                if (this.AddressCompRef) {
                    if (address.addressType === 0) {
                        if (!this.AddressCompRef.verifyLegalAddress(address.lineOne)) {
                            passedValidation = false;
                        }
                        if (!this.AddressCompRef.verifyCity(address.city, index)) {
                            passedValidation = false;
                        }
                        if (!this.AddressCompRef.verifyZip(address.zip, index)) {
                            passedValidation = false;
                        }
                    } else if (address.addressType === 3) {
                        if (!this.state.isAPOAddress) {
                            if (this.state.isForeignAddress) {
                                if (!this.AddressCompRef.verifySpecialMailingCountry(address.country)) {
                                    passedValidation = false;
                                }
                            } else {
                                if (!this.AddressCompRef.verifySpecialMailingState(address.state)) {
                                    passedValidation = false;
                                }
                                if (!this.AddressCompRef.verifySpecialMailingZip(address.zip)) {
                                    passedValidation = false;
                                }
                            }
                            if (!this.AddressCompRef.verifySpecialMailingCity(address.city)) {
                                passedValidation = false;
                            }
                        }
                        if (!this.AddressCompRef.verifySpecialMailingAddress(address.lineOne)) {
                            passedValidation = false;
                        }
                    } else {
                        if (!this.AddressCompRef.verifyOtherAddresses(address.lineOne)) {
                            passedValidation = false;
                        }
                        if (!this.AddressCompRef.verifyCity(address.city, index)) {
                            passedValidation = false;
                        }
                        if (!this.AddressCompRef.verifyZip(address.zip, index)) {
                            passedValidation = false;
                        }
                    }
                }
            });
        }
        return passedValidation;
    }

    requireDuration = () => {
        if (this.state.newServiceType === 'renew') {
            if (this.state.price) {
                return true;
            }
            return false;
        }
        return true;
    }

    cdlSelfCertificationRequired = () => {
        if (!this.shouldShowCDLSelfCert()) {
            return true;
        }
        else if (this.state.cdlSelfCertificationType !== undefined  &&
                this.state.cdlSelfCertificationType !== '') {
            return true;
        }

        return false;
    }

    requireLegalPresence = () => {
        const displayCitizenQuestion = this.state.selectedCredential.contextData.displaycitizenquestion;
        const serviceType = this.state.newServiceType;

        // do we qualify to collect citizen information?
        if (!displayCitizenQuestion) {
            return true;
        }
        // is it the correct service type to collect citizen information?
        else if (serviceType !== 'renew') {
            return true;
        }
        // we need to collect both citizen and legal presence (if citizen == false)
        else if (this.state.citizen !== undefined) {
            if (this.state.citizen === true) {
                return true;
            }
            else if (this.state.legalPresence !== undefined) {
                return true;
            }
        }

        return false;
    }

    requireVoterAnswers = () => {
        if (this.state.declineVoter) {
            return true;
        } else {
            if (this.qualifiesForLegalPresence()) {
                if (this.state.resident !== undefined) {
                    return true;
                }
                else if (this.state.citizen !== undefined && this.state.resident !== undefined) {
                    return true;
                }
            }
            if (this.state.citizen !== undefined && this.state.resident !== undefined) {
                return true;
            }
            return false;
        }
    }

    requireSchoolBusQuestion = () => {
        return this.state.keepSchoolBusEndorsement === true ? this.state.takenSchoolBusClinic !== null : true;
    };

    requireHazardousMaterialsQuestion = () => {
        return this.state.keepHazardousMaterialsEndorsement === true ? this.state.submittedFingerprints !== null : true;
    }

    requireSchoolBusTestYesIfApplicable = () => {
        return this.state.keepSchoolBusEndorsement === true ? this.state.takenSchoolBusClinic !== false : true;
    }

    requireFingerprintsYesIfApplicable = () => {
        return this.state.keepHazardousMaterialsEndorsement === true ? this.state.submittedFingerprints !== false : true;
    }

    requireReason = () => {
        if (this.state.newServiceType === 'replacement') {
            if (this.state.replacementReason) {
                return true;
            }
            return false;
        }
        return true;
    }

    onClickCancel = () => {
        window.location.pathname="/select-credential";
    }

    mapEyeColor = () => {
        const options = Object.keys(this.state.eyeColorList).map((color: string) => {
            return <option key={color} value={Object.values(this.state.eyeColorList[color])[0] as any} >{color}</option>;
        });
        return options;
    }

    mapGender = () => {
        const options = Object.keys(this.state.genderList).map((gender: string) => {
            return <option key={gender} value={Object.values(this.state.genderList[gender])[0] as any} >{gender}</option>;
        });
        return options;
    }

    mapReplacementReasonList = () => {
        const options = Object.keys(this.state.replacementReasonList).map((reason: string) => {
            return <option key={reason} value={Object.values(this.state.replacementReasonList[reason])[0] as any} >{reason}</option>;
        });
        return options;
    }

    medicalRequired = () => {
        const { medicalDisclosure } = this.state;
        if (medicalDisclosure === undefined ) {
            return false;
        }
        return true;
    }

    organDonorRequired = () => {
        const { organDonor } = this.state;
        if (organDonor === undefined ) {
            return false;
        }
        return true;
    }

    setOrganDonorYes = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.organDonor !== undefined) {
            const currentValue = this.state.organDonor;
            if (currentValue === true) {
                this.setState({
                    organDonor: undefined
                });
            } else {
                this.setState({
                    organDonor: true
                })
            }
        } else {
            this.setState({
                organDonor: true
            });
        }
    }

    setOrganDonorNo = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.organDonor !== undefined) {
            const currentValue = this.state.organDonor;
            if (currentValue === false) {
                this.setState({
                    organDonor: undefined
                });
            } else {
                this.setState({
                    organDonor: false
                })
            }
        } else {
            this.setState({
                organDonor: false
            });
        }
    }

    setMedicalDisclosureYes = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.medicalDisclosure !== undefined) {
            const currentValue = this.state.medicalDisclosure;
            if (currentValue === true) {
                this.setState({
                    medicalDisclosure: undefined
                });
            } else {
                this.setState({
                    medicalDisclosure: true
                })
            }
        } else {
            this.setState({
                medicalDisclosure: true
            });
        }
    }

    setMedicalDisclosureNo = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.medicalDisclosure !== undefined) {
            const currentValue = this.state.medicalDisclosure;
            if (currentValue === false) {
                this.setState({
                    medicalDisclosure: undefined
                });
            } else {
                this.setState({
                    medicalDisclosure: false
                })
            }
        } else {
            this.setState({
                medicalDisclosure: false
            });
        }
    }

    setCitizenYes = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.citizen !== undefined) {
            const currentValue = this.state.citizen;
            if (currentValue === true) {
                this.setState({
                    citizen: undefined
                });
            } else {
                if (this.qualifiesForLegalPresence()) {
                    this.setState({
                        citizen: true
                    });
                }
                else {
                    this.setState({
                        citizen: true,
                        declineVoter: false
                    });
                }
            }
        } else {
            if (this.qualifiesForLegalPresence()) {
                this.setState({
                    citizen: true
                });
            }
            else {
                this.setState({
                    citizen: true,
                    declineVoter: false
                });
            }
        }
    }

    setCitizenNo = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.citizen !== undefined) {
            const currentValue = this.state.citizen;
            if (currentValue === false) {
                this.setState({
                    citizen: undefined
                });
            } else {
                if (this.qualifiesForLegalPresence()){
                    this.setState({
                        citizen: false,
                    });
                }
                else {
                    this.setState({
                        citizen: false,
                        declineVoter: false
                    });
                }
            }
        } else {
            if (this.qualifiesForLegalPresence()){
                this.setState({
                    citizen: false,
                });
            }
            else {
                this.setState({
                    citizen: false,
                    declineVoter: false
                });
            }
        }
    }

    setKeepSchoolBusEndorsement = (value: boolean | null) => {
        this.setState({
            keepSchoolBusEndorsement: value,
            takenSchoolBusClinic: null
        });
    }

    setTakenSchoolBusClinic = (value: boolean) => {
        this.setState({
            takenSchoolBusClinic: value
        })
    }

    setKeepHazardousMaterialsEndorsement = (value: boolean | null) => {
        this.setState({
            keepHazardousMaterialsEndorsement: value,
            submittedFingerprints: null
        })
    }

    setSubmittedFingerprints = (value: boolean) => {
        this.setState({
            submittedFingerprints: value
        })
    }

    shouldShowCDLSelfCert = () => {
        const licenseType = this.state.selectedCredential.contextData.licensetype;
        // TODO: get license type value from list rather than hard-coding at some point
        if (licenseType === `Commercial Driver's License`) {
            return true;
        }

        return false;
    }

    shouldShowLegalPresence = () => {
        if (this.qualifiesForLegalPresence() &&
            this.state.citizen == false) {
            return true;
        }

        return false;
    }

    qualifiesForLegalPresence = () => {
        const displayCitizenQuestion = this.state.selectedCredential.contextData.displaycitizenquestion;
        const serviceType = this.state.newServiceType;

        if (displayCitizenQuestion &&
            serviceType === 'renew') {
                return true;
        }

        return false;
    }

    setSelfCertificationNI = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const type = 'ni';

        this.setSelfCertForType(type);
    }

    setSelfCertificationNA = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const type = 'na';

        this.setSelfCertForType(type);
    }

    setSelfCertificationEI = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const type = 'ei';

        this.setSelfCertForType(type);
    }

    setSelfCertificationEA = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const type = 'ea';

        this.setSelfCertForType(type);
    }

    setSelfCertForType = (type: string) => {
        if (this.state.cdlSelfCertificationType !== undefined) {
            const currentValue = this.state.cdlSelfCertificationType;
            if (currentValue === type) {
                this.setSelfCertificationType(undefined);
            } else {
                this.setSelfCertificationType(type);
            }
        } else {
            this.setSelfCertificationType(type);
        }
    }

    setSelfCertificationType = (type: string | undefined) => {
        this.setState({
            cdlSelfCertificationType: type
        })
    }

    isSelfCertificationCbChecked = (type: string) => {
        if (type === this.state.cdlSelfCertificationType) {
            return true;
        }

        return false;
    }

    setLegalPresenceYes = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.legalPresence !== undefined) {
            const currentValue = this.state.legalPresence;
            if (currentValue === true) {
                this.setState({
                    legalPresence: undefined
                });
            } else {
                this.setState({
                    legalPresence: true
                })
            }
        } else {
            this.setState({
                legalPresence: true
            });
        }
    }

    setLegalPresenceNo = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.legalPresence !== undefined) {
            const currentValue = this.state.legalPresence;
            if (currentValue === false) {
                this.setState({
                    legalPresence: undefined
                });
            } else {
                this.setState({
                    legalPresence: false
                })
            }
        } else {
            this.setState({
                legalPresence: false
            });
        }
    }

    setResidentYes = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.resident !== undefined) {
            const currentValue = this.state.resident;
            if (currentValue === true) {
                this.setState({
                    resident: undefined
                });
            } else {
                this.setState({
                    resident: true,
                    declineVoter: false
                })
            }
        } else {
            this.setState({
                resident: true,
                declineVoter: false
            });
        }
    }

    setResidentNo = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (this.state.resident !== undefined) {
            const currentValue = this.state.resident;
            if (currentValue === false) {
                this.setState({
                    resident: undefined
                });
            } else {
                this.setState({
                    resident: false,
                    declineVoter: false
                })
            }
        } else {
            this.setState({
                resident: false,
                declineVoter: false
            });
        }
    }

    onInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { name } = event.currentTarget;
        const { value } = event.currentTarget;
        this.setState(
            (previousState: any) => ({
                ...previousState,
                [name]: value
             })
        );
    }

    onHeightFeetChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        const heightFeet = formatHeightFeet(value);
        this.setState({
            heightFeet: heightFeet
        });
    }
    onHeightInchesChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        const heightIn = formatHeightInches(value);
        this.setState({
            heightInches: heightIn
        });
    }

    declineVoterRegistration = () => {
        if (this.state.declineVoter === false || this.state.declineVoter === undefined) {
            // if citizen and legal presence are required, don't set them to undefined
            if (this.qualifiesForLegalPresence()) {
                this.setState({
                    declineVoter: true,
                    resident: undefined
                });
            }
            else {
                this.setState({
                    declineVoter: true,
                    resident: undefined,
                    citizen: undefined
                });
            }
        } else {
            this.setState({
                declineVoter: false
            });
        }
    }

    receiveEmails = () => {
        this.setState({receiveEmail: !this.state.receiveEmail});
    }

    receiveSMSs = () => {
        this.setState({receiveSMS: !this.state.receiveSMS});
    }

    phoneInput = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        this.setState({
            [name]: value
        });
    }

    emailIsValid = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    verifyEmail = () => {
        const value = this.state.email;
        this.compareEmail();
        if (this.emailIsValid(value) || value === '') {
            this.setState({
                emailError: '',
                emailInvalid: undefined
            });
            return true;
        } else {
            this.setState({
                emailError: getText(this.state.content, 'EmailInvalid'),
                emailInvalid: true
            });
            return false;
        }
    }

    compareEmail = () => {
        if (this.state.emailConfirmation !== this.state.email) {
                this.setState({
                    emailConfirmationError: getText(this.state.content, 'EmailDoesNotMatch'),
                    emailConfirmationInvalid: true
                });
                return false;
        } else {
            this.setState({
                emailConfirmationError: '',
                emailConfirmationInvalid: undefined
            });
            return true;
        }
    }

    phoneFormat = () => {
        let value = this.state.phone;
        value = value && value.replace(/[^\d]/g, '');
        this.comparePhone();
        if (value === '') {
            this.setState({
                phoneError: '',
                phoneInvalid: undefined,
                phone: value
            });
            return true;
        } else if (value.length < 10 || value.length > 15) {
                this.setState({
                    phoneError: getText(this.state.content, 'PhoneMustBeEleven'),
                    phoneInvalid: true,
                    phone: value
                });
                return false;
        } else {
            this.setState({
                phoneError: '',
                phoneInvalid: undefined,
                phone: value
            });
            return true;
        }
    }

    comparePhone = () => {
        if (this.state.phoneConfirmation !== this.state.phone) {
                this.setState({
                    phoneConfirmationError: getText(this.state.content, 'PhoneDoesNotMatch'),
                    phoneConfirmationInvalid: true
                });
                return false;
        } else {
            this.setState({
                phoneConfirmationError: '',
                phoneConfirmationInvalid: undefined
            });
            return true;
        }
    }

    phoneConfirmationInput = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        this.setState({
            phoneConfirmation: value
        });
    }

    phoneConfirmationFormat = (event: React.SyntheticEvent<HTMLInputElement>) => {
        let { value } = event.currentTarget;
        value = value.replace(/[^\d]/g, '');
        if (this.comparePhone()) {
            if (value === '') {
                this.setState({
                    phoneConfirmationError: '',
                    phoneConfirmationInvalid: undefined,
                    phoneConfirmation: value
                });
                return true;
            } else if (value.length < 10 || value.length > 15) {
                this.setState({
                    phoneConfirmationError: getText(this.state.content, 'PhoneMustBeEleven'),
                    phoneConfirmationInvalid: true,
                    phoneConfirmation: value
                });
                return false;

            } else {
                this.setState({
                    phoneConfirmationError: '',
                    phoneConfirmationInvalid: undefined,
                    phoneConfirmation: value
                });
                return true;
            }
        } else {
            this.setState({
                phoneConfirmation: value
            });
            return false;
        }
    }

    heightFeetFormat = () => {
        const value = this.state.heightFeet;
        if (!value || value === '') {
                this.setState({
                    heightFeetError: getText(this.state.content, 'HeightFeetRequired'),
                    heightFeetInvalid: true
                });
                return false;
        } else {
            this.setState({
                heightFeetError: '',
                heightFeetInvalid: undefined
            });
            return true;
        }
    }

    heightInchesFormat = () => {
        const value = this.state.heightInches;
        if (!value || value === '') {
                this.setState({
                    heightInchesError: getText(this.state.content, 'HeightInchesRequired'),
                    heightInchesInvalid: true
                });
                return false;
        } else {
            this.setState({
                heightInchesError: '',
                heightInchesInvalid: undefined
            });
            return true;
        }
    }

    weightFormat = () => {
        const value = this.state.weight;
        if (!value || value === '') {
                this.setState({
                    weightError: getText(this.state.content, 'WeightRequired'),
                    weightInvalid: true
                });
                return false;
        } else {
            this.setState({
                weightError: '',
                weightInvalid: undefined,
                weight: formatWeight(value)
            });
            return true;
        }
    }

    displayFeedback = (invalid?: boolean) => {
        if (invalid) {
            return "true";
        }
        return undefined;
    }

    buttons = () => {
        return(
            <div className="row mt-4">
                <div className="col-xl-4 col-12">
                    <Button
                        className="cancel-btn"
                        onClick={this.onClickCancel}
                    >
                        {getText(this.state.content, 'CancelButton')}
                    </Button>
                </div>
                <div className="col-xl-4 col-12">
                    <br />
                </div>
                <div className="col-xl-4 col-12">
                    <Button
                        type='submit'
                        className="general-btn"
                    >
                        {getText(this.state.content, 'SubmitButton')}
                    </Button>
                </div>
            </div>
        );
    }

    selectDurationRenew = (event: React.SyntheticEvent<any>) => {
        const { value } = event.currentTarget;
        const { selectedOptions } = event.currentTarget;
        const { selectedCredential } = this.state;
        if (selectedCredential && selectedCredential.prices) {
            let selectedPriceObj = {};
            selectedCredential.prices.forEach((priceObj: any) => {
                if (selectedOptions[0].innerText === priceObj.name + ' ' + parseCurrency(priceObj.currency)) {
                    selectedPriceObj = priceObj;
                }
            });
            this.setState({
                price: value,
                selectedPriceObj: selectedPriceObj
            })
        }
    }

    checkForRenew = () => {
        const { selectedCredential } = this.state;
        if (this.state.newServiceType === 'renew') {
            const prices: Array<any> = selectedCredential && selectedCredential.prices && selectedCredential.prices.filter((price: any) => price.name !== "Replacement");
            const duration = prices.map((item: any, index: number) => {
                return <option key={index} value={item.currency} >{item.name + ' ' + parseCurrency(item.currency)}</option>;
            });

            const isRestricted = this.renewalIsRestricted(selectedCredential);

            return (
                <div className="row mt-4">
                    <div className="col-xl-4 col-4">
                        <Label className="font-weight-bold">
                            {getText(this.state.content, 'Duration')}
                        </Label>
                    </div>
                    {
                        isRestricted ?
                        <div className="col-xl-8 col-8">
                            {getText(this.state.content, 'RestrictedDurationText')}
                        </div>
                        :
                        <div className="col-xl-8 col-8">
                            <Input
                                required={true}
                                type="select"
                                name="price"
                                value={this.state.price}
                                onChange={this.selectDurationRenew}
                            >
                                <option />
                                {duration}
                            </Input>
                        </div>
                    }
                </div>
            );
        } else {
            const price = selectedCredential && selectedCredential.prices && selectedCredential.prices.filter((price: any) => price.name === "Replacement")
            if (!this.state.price) {
                this.setState({
                    price: price && price[0].currency,
                    selectedPriceObj: price[0]
                });
            }
            return (
                <React.Fragment>
                    <div className="row mt-4">
                        <div className="col-xl-4 col-4">
                            <Label className="font-weight-bold">
                                {getText(this.state.content, 'ReplacementReason')}
                            </Label>
                        </div>
                        <div className="col-xl-8 col-8">
                            <Input
                                required={true}
                                type="select"
                                value={this.state.replacementReason}
                                name="replacementReason"
                                onChange={this.onInputChange}
                            >
                                <option />
                                {this.mapReplacementReasonList()}
                            </Input>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }

    returnToEdit = () => {
        this.setState({renderConfirm: false})
    }

    toggleErrorAlert = () => {
        this.setState({displayErrorAlert: !this.state.displayErrorAlert});
    }

    phoneRequired = () => {
        const { currentPhone, receiveSMS} = this.state;

        if (!currentPhone && receiveSMS) {
            return true;
        }
        return undefined;
    }

    emailRequired = () => {
        const { currentEmail, receiveEmail} = this.state;

        if (!currentEmail && receiveEmail) {
            return true;
        }
        return undefined;
    }

    getVoterRegistrationHeader = (content: any) => {
        let header = getText(content, 'VoterRegHeader');

        if (this.qualifiesForLegalPresence()) {
            header += ' / Real ID Citizenship Confirmation';
        }

        return header;
    }

    getVoterConfidentialityStatement = (content: any) => {
        let statement = getText(content, 'VoterConfidentialityStatement')

        if (this.qualifiesForLegalPresence()) {
            statement += ' Since you are applying for a Real ID, you must answer the ' +
                'citizenship question, but it will not affect your voter registration status';
        }

        return statement;
    }

    showSchoolBusQuestion = () => {
        const endorsements = this.state.selectedCredential?.endorsements as {code: string}[];

        if (!endorsements) return false;

        return endorsements.some(x => x.code === 'A' || x.code === 'S');
    }
    
    showHazardousMaterialsQuestion = () => {
        const endorsements = this.state.selectedCredential?.endorsements as {code: string}[];

        if (!endorsements) return false;

        return endorsements.some(x => x.code === 'H' || x.code === 'X');
    }

    SchoolBusEndorsementQuestion = (content: any) => {
        return <>
            <div className="col-xl-12">
                <hr className="real-id-divider"/>
            </div>
            <div className="row mt-4">
                <div className="col-xl-6 col-6">
                    <Label className="font-weight-bold">
                        {/* TODO getText(content, 'CDLQuestion1A') */}
                        1a. Do you wish to keep your school bus endorsement?
                    </Label>
                </div>
                <div className="col-xl-2 col-2">
                    <Input
                        type="checkbox"
                        checked={this.state.keepSchoolBusEndorsement === true}
                        onChange={() => this.setKeepSchoolBusEndorsement(true)}
                    />{' '}<strong className="label-color">{getText(content, 'Yes')}</strong>
                </div>
                <div className="col-xl-2 col-2">
                    <Input
                        type="checkbox"
                        checked={this.state.keepSchoolBusEndorsement === false}
                        onChange={() => this.setKeepSchoolBusEndorsement(false)}
                    />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                </div>
            </div>
            {this.state.keepSchoolBusEndorsement ?
                <div className="row mt-4">
                    <div className="col-xl-6 col-6 edits-text-greyed">
                        <Label className="font-weight-bold">
                            {/* TODO getText(content, 'CDLQuestion1B') */}
                            1b. If yes, have you taken the School Bus Clinic and the written test within the past 6 months?
                        </Label>
                    </div>
                    <div className="col-xl-2 col-2">
                        <Input
                            type="checkbox"
                            checked={this.state.takenSchoolBusClinic === true}
                            onChange={() => this.setTakenSchoolBusClinic(true)}
                        />{' '}<strong
                        className="label-color">{getText(content, 'Yes')}</strong>
                    </div>
                    <div className="col-xl-2 col-2">
                        <Input
                            type="checkbox"
                            checked={this.state.takenSchoolBusClinic === false}
                            onChange={() => this.setTakenSchoolBusClinic(false)}
                        />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                    </div>
                    <div className="col-xl-2"></div>
                    <div className="col-xl-10 edits-text-greyed">
                        {this.state.takenSchoolBusClinic === false ?
                            <>Your application cannot be processed online.  Please call 802-828-0598</> : <></>}
                    </div>
                </div>
                : <></>
            }
        </>;
    }

    HazardousMaterialsEndorsementQuestion = (content: any) => {
        return <>
            <div className="col-xl-12">
                <hr className="real-id-divider"/>
            </div>
            <div className="row mt-4">
                <div className="col-xl-6 col-6">
                    <Label className="font-weight-bold">
                        {/* TODO getText(content, 'CDLQuestion2A') */}
                        2a. Do you wish to keep Hazardous Materials endorsement?
                    </Label>
                </div>
                <div className="col-xl-2 col-2">
                    <Input
                        type="checkbox"
                        checked={this.state.keepHazardousMaterialsEndorsement === true}
                        onChange={() => this.setKeepHazardousMaterialsEndorsement(true)}
                    />{' '}<strong className="label-color">{getText(content, 'Yes')}</strong>
                </div>
                <div className="col-xl-2 col-2">
                    <Input
                        type="checkbox"
                        checked={this.state.keepHazardousMaterialsEndorsement === false}
                        onChange={() => this.setKeepHazardousMaterialsEndorsement(false)}
                    />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                </div>
            </div>
            {this.state.keepHazardousMaterialsEndorsement ?
                <div className="row mt-4">
                    <div className="col-xl-6 col-6">
                        <Label className="font-weight-bold">
                            {/* TODO getText(content, 'CDLQuestion2B') */}
                            2b. If yes, have your fingerprints (Threat Assessment) been submitted and have you passed a Hazardous Materials knowledge test within the past 6 months?
                        </Label>
                    </div>
                    <div className="col-xl-2 col-2">
                        <Input
                            type="checkbox"
                            checked={this.state.submittedFingerprints === true}
                            onChange={() => this.setSubmittedFingerprints(true)}
                        />{' '}<strong
                        className="label-color">{getText(content, 'Yes')}</strong>
                    </div>
                    <div className="col-xl-2 col-2">
                        <Input
                            type="checkbox"
                            checked={this.state.submittedFingerprints === false}
                            onChange={() => this.setSubmittedFingerprints(false)}
                        />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                    </div>
                    <div className="col-xl-2"></div>
                    <div className="col-xl-10 edits-text-greyed">
                        {this.state.submittedFingerprints === false ?
                            <>Your application cannot be processed online.  Please call 802-828-0598</> : <></>}
                    </div>
                </div>
                : <></>
            }
        </>
    }

    render() {
        const {
            selectedCredential,
            renderConfirm,
            loading,
            content,
            newServiceType,
            heightFeet,
            heightInches,
            weight,
            eyeColor,
            gender,
            organDonor,
            medicalDisclosure,
            declineVoter,
            cdlSelfCertificationType,
            citizen,
            legalPresence,
            resident,
            phone,
            email,
            receiveEmail,
            receiveSMS,
            newAddresses,
            price,
            selectedPriceObj,
            eyeColorList,
            genderList,
            displayErrorAlert,
            alertErrors,
            replacementReason,
            currentPhone,
            currentEmail,
            replacementReasonList,
            keepSchoolBusEndorsement,
            takenSchoolBusClinic,
            keepHazardousMaterialsEndorsement,
            submittedFingerprints

        } = this.state;

        if (loading) {
            return <LoadingIcon />;
        }

        if (renderConfirm) {
            return (
                <Confirmation
                    jwt={this.props.jwt}
                    selectedCredentialId={selectedCredential.id}
                    language={this.props.language}
                    returnToEdit={this.returnToEdit}
                    selectedCredential={selectedCredential}
                    newServiceType={newServiceType}
                    heightFeet={heightFeet}
                    heightInches={heightInches}
                    weight={weight}
                    eyeColor={eyeColor}
                    gender={gender}
                    organDonor={organDonor}
                    medicalDisclosure={medicalDisclosure}
                    declineVoter={declineVoter}
                    cdlSelfCertificationType={cdlSelfCertificationType}
                    citizen={citizen}
                    legalPresence={legalPresence}
                    resident={resident}
                    phone={phone || currentPhone}
                    email={email || currentEmail}
                    receiveEmail={receiveEmail}
                    receiveSMS={receiveSMS}
                    newAddresses={newAddresses}
                    price={price}
                    selectedPriceObj={selectedPriceObj}
                    eyeColorList={eyeColorList}
                    genderList={genderList}
                    addressContent={this.state.addressContent}
                    replacementReason={replacementReason}
                    replacementReasonList={replacementReasonList}
                    keepSchoolBusEndorsement={keepSchoolBusEndorsement}
                    takenSchoolBusClinic={takenSchoolBusClinic}
                    keepHazardousMaterialsEndorsement={keepHazardousMaterialsEndorsement}
                    submittedFingerprints={submittedFingerprints}
                />
            );
        }

        return !renderConfirm && content && selectedCredential && selectedCredential.contextData ?
        (
            <Form onSubmit={this.onClickSubmit}>
                <div className="row">
                    <div>
                        <h1 className="title">
                            {getText(content, 'EditTitle')}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-8 col-12">
                        <div className="row">
                            <div>
                                <h4 className="selected-cred-label">
                                    {getText(content, 'EditSubtitle')}
                                </h4>
                            </div>
                        </div>
                        {this.checkForRenew()}
                        <div className="row mt-4">
                            <div className="col-xl-4 col-4">
                                <Label className="font-weight-bold">
                                    {getText(content, 'HeightFeet')}
                                </Label>
                            </div>
                            <div className="col-xl-8 col-8">
                                <FormGroup>
                                    <Input
                                        required={true}
                                        type="number"
                                        min="1"
                                        max="9"
                                        name="heightFeet"
                                        value={this.state.heightFeet}
                                        onChange={this.onHeightFeetChange}
                                        onBlur={this.heightFeetFormat}
                                        invalid={this.state.heightFeetInvalid}
                                    />
                                    <FormFeedback
                                        invalid={this.displayFeedback(this.state.heightFeetInvalid)}
                                    >
                                        {this.state.heightFeetError}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-4 col-4">
                                <Label className="font-weight-bold">
                                    {getText(content, 'HeightInches')}
                                </Label>
                            </div>
                            <div className="col-xl-8 col-8">
                                <FormGroup>
                                    <Input
                                        required={true}
                                        type="number"
                                        min="0"
                                        max="11"
                                        value={this.state.heightInches}
                                        name="heightInches"
                                        onChange={this.onHeightInchesChange}
                                        onBlur={this.heightInchesFormat}
                                        invalid={this.state.heightInchesInvalid}
                                    />
                                    <FormFeedback
                                        invalid={this.displayFeedback(this.state.heightInchesInvalid)}
                                    >
                                        {this.state.heightInchesError}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-4 col-4">
                                <Label className="font-weight-bold">
                                    {getText(content, 'Weight')}
                                </Label>
                            </div>
                            <div className="col-xl-8 col-8">
                                <FormGroup>
                                    <Input
                                        required={true}
                                        type="number"
                                        min="1"
                                        max="999"
                                        value={this.state.weight}
                                        name="weight"
                                        onChange={this.onInputChange}
                                        onBlur={this.weightFormat}
                                        invalid={this.state.weightInvalid}
                                    />
                                    <FormFeedback
                                        invalid={this.displayFeedback(this.state.weightInvalid)}
                                    >
                                        {this.state.weightError}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="font-weight-bold col-xl-4 col-4">
                                <Label>
                                    {getText(content, 'Eyes')}
                                </Label>
                            </div>
                            <div className="col-xl-8 col-8">
                                <Input
                                    required={true}
                                    type="select"
                                    name="eyeColor"
                                    value={this.state.eyeColor}
                                    onChange={this.onInputChange}
                                >
                                    {this.mapEyeColor()}
                                </Input>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="font-weight-bold col-xl-4 col-4">
                                <Label>
                                    {getText(content, 'Gender')}
                                </Label>
                            </div>
                            <div className="col-xl-8 col-8">
                                <Input
                                    required={true}
                                    type="select"
                                    name="gender"
                                    value={this.state.gender}
                                    onChange={this.onInputChange}
                                >
                                    {this.mapGender()}
                                </Input>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-8 col-8">
                                <Label className="font-weight-bold">
                                    {getText(content, 'OrganDonor')}
                                </Label>
                            </div>
                            <div className="col-xl-2 col-2">
                                <Input
                                    type="checkbox"
                                    checked={this.state.organDonor === true}
                                    onChange={this.setOrganDonorYes}
                                />{' '}<strong className="label-color">{getText(content, 'Yes')}</strong>
                            </div>
                            <div className="col-xl-2 col-2">
                                <Input
                                    type="checkbox"
                                    checked={this.state.organDonor === false}
                                    onChange={this.setOrganDonorNo}
                                />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                            </div>
                        </div>
                        {!this.props.location.state.selectedCredential.contextData.displaymedicalquestion
                        ? <React.Fragment />
                        : <React.Fragment>
                            <div className="row mt-4">
                            <div>
                                <h4 className="edits-column-head">
                                    {getText(content, 'MedicalQuestionHeader')}
                                </h4>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="edits-text-greyed">
                                {getText(content, 'MedicalQuestion')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-8" />
                            <div className="col-xl-2 col-2 col-md-2 col-sm-2">
                                <Input
                                    type="checkbox"
                                    name="medicalDisclosure"
                                    checked={this.state.medicalDisclosure === true}
                                    onChange={this.setMedicalDisclosureYes}
                                />{' '}<strong className="label-color">{getText(content, 'Yes')}</strong>
                            </div>
                            <div className="col-xl-2 col-2 col-md-2 col-sm-2">
                                <Input
                                    type="checkbox"
                                    name="medicalDisclosure"
                                    checked={this.state.medicalDisclosure === false}
                                    onChange={this.setMedicalDisclosureNo}
                                />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                            </div>
                        </div>
                        </React.Fragment>}
                        { this.shouldShowCDLSelfCert() ?
                            <>
                                {this.state.newServiceType === 'renew' ? <>
                                        {
                                            this.showSchoolBusQuestion() ?
                                                this.SchoolBusEndorsementQuestion(content)
                                                : <></>
                                        }
                                        {
                                            this.showHazardousMaterialsQuestion() ?
                                                this.HazardousMaterialsEndorsementQuestion(content)
                                                : <></>
                                        }
                                </> : <></>}
                                <div className="row mt-4">
                                <div>
                                    <h4 className="edits-column-head">
                                        {/* TODO: getText(content, 'CDLSelfCertificationHeader') */}
                                        CDL Self Certification
                                    </h4>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="edits-text-greyed">
                                    {/* TODO: getText(content, 'CDLSelfCertificationInstructions') */}
                                    Please check one box:
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-xl-10 col-10 edits-text-greyed">
                                    <strong className="label-color">
                                        {/* TODO: getText(content, 'CDLSelfCertificationNILabel') */}
                                        NI (Non-Excepted Interstate, Current Medical Certificate Required).
                                    </strong>
                                    <br />
                                    {/* TODO: getText(content, 'CDLSelfCertificationNIText') */}
                                    I certify that I operate or expect to operate in interstate commerce,
                                    and I am both subject to and meet the qualification requirements under 49 CFR part 391, and
                                    I am required to obtain a medical examiner's certificate by 49 CFR §391.45
                                </div>
                                <div className="col-xl-1 col-1">
                                    <Input
                                        type="checkbox"
                                        checked={this.isSelfCertificationCbChecked('ni')}
                                        onChange={this.setSelfCertificationNI}
                                        disabled={!this.requireSchoolBusTestYesIfApplicable() || !this.requireFingerprintsYesIfApplicable()}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-xl-10 col-10 edits-text-greyed">
                                    <strong className="label-color">
                                        {/* TODO: getText(content, 'CDLSelfCertificationNALabel') */}
                                        NA (Non-Excepted Intrastate, Current Medical Certificate Required).
                                    </strong>
                                    <br />
                                    {/* TODO: getText(content, 'CDLSelfCertificationNAText') */}
                                    I certify that I operate only in intrastate commerce and therefore I am subject to and meet
                                    the State's driver medical qualification requirements.
                                </div>
                                <div className="col-xl-1 col-1">
                                    <Input
                                        type="checkbox"
                                        checked={this.isSelfCertificationCbChecked('na')}
                                        onChange={this.setSelfCertificationNA}
                                        disabled={!this.requireSchoolBusTestYesIfApplicable() || !this.requireFingerprintsYesIfApplicable()}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-xl-10 col-10 edits-text-greyed">
                                    <strong className="label-color">
                                        {/* TODO: getText(content, 'CDLSelfCertificationEILabel') */}
                                        EI (Excepted Interstate, Current Medical NOT Required).
                                    </strong>
                                    <br />
                                    {/* TODO: getText(content, 'CDLSelfCertificationEIText') */}
                                    I certify that I operate or expect to operate in interstate commerce but engage exclusively
                                    in transportation or operations excepted under 49 CFR §§390.3(f), 391.2, 391.68 or 398.3 from
                                    all or parts of the qualification requirements of 49 CFR part 391, and I am therefore not
                                    required to obtain a medical examiner's certificate by 49 CFR §391.45.
                                </div>
                                <div className="col-xl-1 col-1">
                                    <Input
                                        type="checkbox"
                                        checked={this.isSelfCertificationCbChecked('ei')}
                                        onChange={this.setSelfCertificationEI}
                                        disabled={!this.requireSchoolBusTestYesIfApplicable() || !this.requireFingerprintsYesIfApplicable()}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-xl-10 col-10 edits-text-greyed">
                                    <strong className="label-color">
                                        {/* TODO: getText(content, 'CDLSelfCertificationEALabel') */}
                                        EA (Excepted Intrastate, Current Medical Certificate NOT Required).
                                    </strong>
                                    <br />
                                    {/* TODO: getText(content, 'CDLSelfCertificationEAText') */}
                                    I certify that I operate in intrastate commerce but engage exclusively in transportation or
                                    operations excepted from all or parts of the State's driver medical qualification.
                                </div>
                                <div className="col-xl-1 col-1">
                                    <Input
                                        type="checkbox"
                                        checked={this.isSelfCertificationCbChecked('ea')}
                                        onChange={this.setSelfCertificationEA}
                                        disabled={!this.requireSchoolBusTestYesIfApplicable() || !this.requireFingerprintsYesIfApplicable()}
                                    />
                                </div>
                            </div>
                        </>
                        :
                        <></>
                        }

                        <div className="row mt-4">
                            <div>
                                <h4 className="edits-column-head">
                                    {this.getVoterRegistrationHeader(content)}
                                </h4>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="edits-text-greyed">
                                {this.getVoterConfidentialityStatement(content)}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-xl-8 col-8 edits-text-greyed">
                                {getText(content, 'USCitizen')}
                            </div>
                            <div className="col-xl-2 col-2">
                                <Input
                                    type="checkbox"
                                    checked={this.state.citizen === true}
                                    onChange={this.setCitizenYes}
                                />{' '}<strong className="label-color">{getText(content, 'Yes')}</strong>
                            </div>
                            <div className="col-xl-2 col-2">
                                <Input
                                    type="checkbox"
                                    checked={this.state.citizen === false}
                                    onChange={this.setCitizenNo}
                                />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                            </div>
                        </div>
                        { this.shouldShowLegalPresence() ?
                            <div className="row mt-3">
                                <div className="col-xl-8 col-8 edits-text-greyed">
                                    <ul>
                                        <li>
                                            {/* TODO: getText(content, 'LegalPresence') */}
                                            If you are NOT a U.S. Citizen, do you have proof of legal presence?
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-2 col-2">
                                    <Input
                                        type="checkbox"
                                        checked={this.state.legalPresence === true}
                                        onChange={this.setLegalPresenceYes}
                                    />{' '}<strong className="label-color">{getText(content, 'Yes')}</strong>
                                </div>
                                <div className="col-xl-2 col-2">
                                    <Input
                                        type="checkbox"
                                        checked={this.state.legalPresence === false}
                                        onChange={this.setLegalPresenceNo}
                                    />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                                </div>
                            </div>
                        :
                            <></>
                        }

                        {this.qualifiesForLegalPresence() ?
                            <div className="row mt-3">
                                <div className="col-xl-12">
                                    <hr className="real-id-divider"/>
                                </div>
                            </div>
                            :
                            <></>}

                        <div className="row mt-3">
                            <div className="col-xl-8 col-8 edits-text-greyed">
                                {getText(content, 'Resident')}
                            </div>
                            <div className="col-xl-2 col-2">
                                <Input
                                    type="checkbox"
                                    checked={this.state.resident === true}
                                    onChange={this.setResidentYes}
                                />{' '}<strong className="label-color">{getText(content, 'Yes')}</strong>
                            </div>
                            <div className="col-xl-2 col-2">
                                <Input
                                    type="checkbox"
                                    checked={this.state.resident === false}
                                    onChange={this.setResidentNo}
                                />{' '}<strong className="label-color">{getText(content, 'No')}</strong>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="edits-text-greyed">
                                {getText(content, 'VoterRegistration')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-9 col-9" />
                            <div className="col-xl-3 col-3">
                                <Input
                                    type="checkbox"
                                    checked={this.state.declineVoter}
                                    onChange={this.declineVoterRegistration}
                                />{' '}<strong className="label-color">{getText(content, 'Decline')}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-12">
                        <div className="row">
                            <div>
                                <h4 className="edits-column-head">
                                    {getText(content, 'ConfirmationTitle')}
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <div className="edits-text-greyed">
                                    {getText(content, 'ConfirmationStatement')}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div>
                                <div className="edits-text-greyed">
                                    {getText(content, 'CurrentEmail')}&nbsp;
                                    <strong>{this.state.currentEmail || 'N/A'}</strong>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-xl-3 col-3">
                                <Label for="email" className="font-weight-bold">
                                    {getText(content, 'Email')}
                                </Label>
                            </div>
                            <div className="col-xl-9 col-9">
                                <FormGroup>
                                    <Input
                                        type="email"
                                        id="emailInput"
                                        name="email"
                                        onChange={this.onInputChange}
                                        onBlur={this.verifyEmail}
                                        invalid={this.state.emailInvalid}
                                        required={this.emailRequired()}
                                    />
                                    <FormFeedback
                                        invalid={this.displayFeedback(this.state.emailInvalid)}
                                    >
                                        {this.state.emailError}
                                    </FormFeedback>
                                </FormGroup>
                                <div className="edits-text-greyed">
                                    {getText(content, 'ConfirmEmail')}
                                </div>
                                <FormGroup>
                                    <Input
                                        type="email"
                                        id="emailConfirmationInput"
                                        name="emailConfirmation"
                                        onChange={this.onInputChange}
                                        onBlur={this.compareEmail}
                                        invalid={this.state.emailConfirmationInvalid}
                                        required={this.emailRequired()}
                                    />
                                    <FormFeedback
                                        invalid={this.displayFeedback(this.state.emailConfirmationInvalid)}
                                    >
                                        {this.state.emailConfirmationError}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                            <div className="edits-text-greyed">
                                <Input
                                    type="checkbox"
                                    checked={this.state.receiveEmail}
                                    onChange={this.receiveEmails}
                                />
                                {' '}
                                {getText(content, 'EmailConfirmationCheckbox')}
                            </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div>
                                <div className="edits-text-greyed">
                                    {getText(content, 'CurrentPhone')}&nbsp;
                                    <strong>{this.state.currentPhone || 'N/A'}</strong>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-xl-3 col-3">
                                <Label for="phone" className="font-weight-bold">
                                    {getText(content, 'Phone')}
                                </Label>
                            </div>
                            <div className="col-xl-9 col-9">
                                <FormGroup>
                                    <Input
                                        id="phoneInput"
                                        name="phone"
                                        onChange={this.phoneInput}
                                        onBlur={this.phoneFormat}
                                        value={this.state.phone}
                                        invalid={this.state.phoneInvalid}
                                        required={this.phoneRequired()}
                                    />
                                    <FormFeedback
                                        invalid={this.displayFeedback(this.state.phoneInvalid)}
                                    >
                                        {this.state.phoneError}
                                    </FormFeedback>
                                </FormGroup>
                                <div className="edits-text-greyed">
                                    {getText(content, 'ConfirmPhone')}
                                </div>
                                <FormGroup>
                                    <Input
                                        id="phoneConfirmationInput"
                                        name="phoneConfirmation"
                                        onChange={this.phoneConfirmationInput}
                                        onBlur={this.phoneConfirmationFormat}
                                        value={this.state.phoneConfirmation}
                                        invalid={this.state.phoneConfirmationInvalid}
                                        required={this.phoneRequired()}
                                    />
                                    <FormFeedback
                                        invalid={this.displayFeedback(this.state.phoneConfirmationInvalid)}
                                    >
                                        {this.state.phoneConfirmationError}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                            <div className="edits-text-greyed">
                                <Input
                                    type="checkbox"
                                    checked={this.state.receiveSMS}
                                    onChange={this.receiveSMSs}
                                />
                                {' '}
                                {getText(content, 'PhoneConfirmationCheckbox')}
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <h4 className="edits-column-head mt-4">
                                    {getText(content, 'VotersOathTitle')}
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <div className="edits-text-greyed mt-2">
                                    <i>{getText(content, 'VotersOath')}</i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <div className="edits-text-greyed mt-4">
                                    {getText(content, 'VotersOath2')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-8 col-12">
                        {this.state.addressContent ? (
                            <AddressManagement
                                ref={(ref) => {
                                    this.AddressCompRef = ref
                                }}
                                addressContent={this.state.addressContent}
                                authConfirm={this.props.authConfirm}
                                authText={this.props.authText}
                                newAddresses={this.state.newAddresses}
                                setNewAddressState={this.setNewAddressState}
                                spMailingHelpModalContent={this.state.spMailingHelp}
                                fieldOptions={this.state.fieldOptions}
                                isForeignAddress={this.state.isForeignAddress}
                                isAPOAddress={this.state.isAPOAddress}
                                isForeignAddressState={this.isForeignAddressState}
                                isAPOAddressState={this.isAPOAddressState}
                                language={this.props.language}
                            />
                        ) : (
                            <React.Fragment />
                        )}
                    </div>
                    <div className="d-flex align-items-end flex-column col-xl-4 col-12">
                        <div className="edits-text-greyed mt-auto">
                            <div>
                                {getText(content, 'FeeStatement') + ' '}
                                <strong>{ parseCurrency(Number(this.state.price))}</strong>
                            </div>
                            <div className="mt-4">
                                {getText(content, 'ConfirmationStatement')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <ErrorAlert
                            errors={alertErrors}
                            displayErrors={displayErrorAlert}
                            toggleErrorDisplay={this.toggleErrorAlert}
                        />
                    </div>
                </div>
                {this.buttons()}
            </Form>
        ) : (
            <Redirect to="/select-credential" />
        );
    }
}

export default EditInformation;
