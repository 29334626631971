import React from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {
    CredentialSelection,
    EditInformation,
    NotFound404,
    PrintReceipt
} from './views';
import {AuthFromValues} from "./types";
import {TransactionHistory} from "./workflows/TransactionHistory/TransactionHistory";

interface Props {
    authConfirm: (
        saveAuthResults: ({authLoading, authErrors, displayAuthErrors}: {authLoading: boolean, displayAuthErrors: boolean, authErrors: any[]}) => void
    ) => (authFormValues: AuthFromValues, reCaptchaToken: string) => Promise<boolean>
    authText: any
    jwt: string;
    language: string
}
class AuthenticatedApp extends React.Component <Props> {
    state = {
        redirectPath: '/authenticate',
        redirect: false,
        queryParams: null
    }
    componentDidMount() {
        // Parse the JWT here
        this.redirectToPage();
    }

    redirectToPage = () => {
        if (this.props.jwt) {
            const path = window.location.pathname !== '/'
                && window.location.pathname !== '/authenticate' ? window.location.pathname : '/select-credential';
            const queryParams = new URLSearchParams(window.location.search);
            this.setState({
                redirect: true,
                redirectPath: path,
                queryParams: queryParams
            });
        } else {
            this.setState({
                redirect: true,
                redirectPath: '/authenticate'
            });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.jwt) {
            this.setState({
                redirect: true,
                redirectPath: '/authenticate'
            });
        }
    }

    // this was moved to a render function to prevent the component from being entirely re-rendered whenever props changed
    renderEditInfo = (props: any) => <EditInformation
        authConfirm={this.props.authConfirm}
        authText={this.props.authText}
        jwt={this.props.jwt}
        language={this.props.language}
        {...props}
    />

    render() {
        const {
            redirectPath,
            redirect,
            queryParams
        } = this.state;
        const {
            language
        } = this.props;
        return (
            <React.Fragment>
                <Switch>
                    <Route
                        path="/select-credential"
                        render={() => <CredentialSelection
                            language={language}
                            queryParams={queryParams}
                        />}
                    />
                    <Route
                        path="/edit-information"
                        component={this.renderEditInfo}
                    />
                    <Route
                        path="/transaction-history"
                        component={() => <TransactionHistory />}
                    />
                    <Route
                        path="/print-receipt"
                        render={() => <PrintReceipt language={language} />}
                    />
                    <Route
                        path="*"
                        render={() => <NotFound404 />}
                    />
                </Switch>
                {redirect && <Redirect to={redirectPath} />}
            </React.Fragment>
        );
    }
}

export default AuthenticatedApp;
